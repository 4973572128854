import React, { useState, useEffect,useRef  } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Navbar, Nav, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { getIp, userId, formatDate } from "./Vars";
import { useNavigate } from "react-router-dom";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { toast, Toaster } from "react-hot-toast";
import TimeOnlyComponent from "./convert.time";
import BottomNavBar from "./bottomnav";
import "./App.css";

const NewBet = () => {
  const navigate = useNavigate();
  const [fingerprint, setFingerprint] = useState("");
  const [combione, setCombione] = useState("");
  const [combitwo, setCombitwo] = useState("");
  const [combithree, setCombithree] = useState("");
  const [bets, setBets] = useState([]);
  const [selectedBets, setSelectedBets] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [betToEdit, setBetToEdit] = useState(null);
  const [betToDelete, setBetToDelete] = useState(null);

  const inputOneRef = useRef(null);
  const inputTwoRef = useRef(null);
  const inputThreeRef = useRef(null);

  const betsData = sessionStorage.getItem("bet_data");
  const parsedBets = betsData ? JSON.parse(betsData) : [];

  if (userId() === 0) {
    navigate("/");
  }

  const validateInput = (value) => {
    const number = parseInt(value, 10);
    return number >= 1 && number <= 49 && value.length <= 2;
  };

  useEffect(() => {
    const generateFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const visitorId = result.visitorId;
      setFingerprint(visitorId);
    };
    generateFingerprint();

    if (parsedBets) {
      const today = new Date().toISOString().split("T")[0];
      const filteredBets = parsedBets.filter(
        (bet) => bet.date.split(" ")[0] === today
      );
      const sortedBets = filteredBets.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setBets(sortedBets);
    }

    // Focus the first input on load
    if (inputOneRef.current) {
      inputOneRef.current.focus();
    }
  }, []);

  const handleInputChange = (setter, nextRef) => (e) => {
    const value = e.target.value;
    if (/^\d{0,2}$/.test(value)) {
      setter(value);
      if (value.length === 2 && nextRef && nextRef.current) {
        nextRef.current.focus();
      }
    }
  };

  const handleBlur = (setter) => (e) => {
    const value = e.target.value;
    if (!validateInput(value)) {
      setter("");
    } else {
      setter(value.padStart(2, "0"));
    }
  };

  const clear = () => {
    setCombione("");
    setCombitwo("");
    setCombithree("");
    if (inputOneRef.current) {
      inputOneRef.current.focus();
    }
  };

  const handleSaveButton = () => {
    if (
      validateInput(combione) &&
      validateInput(combitwo) &&
      validateInput(combithree)
    ) {
      const data = {
        combione,
        combitwo,
        combithree,
        status: 1,
        fingerprint,
        userId: userId(),
        date: formatDate(new Date()),
      };

      const existingData = JSON.parse(sessionStorage.getItem("bet_data")) || [];
      existingData.push(data);
      const today = new Date().toISOString().split("T")[0];
      const filteredBets = existingData.filter(
        (bet) => bet.date.split(" ")[0] === today
      );
      const sortedBets = filteredBets.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      sessionStorage.setItem("bet_data", JSON.stringify(sortedBets));
      clear();
      setBets(sortedBets);
      toast.success("Bet saved!");
    } else {
      toast.error("Please enter valid numbers between 01 and 49.");
    }
  };

  const handleCheckboxChange = (bet) => {
    setSelectedBets((prevSelectedBets) => {
      if (prevSelectedBets.includes(bet)) {
        return prevSelectedBets.filter((selectedBet) => selectedBet !== bet);
      } else {
        return [...prevSelectedBets, bet];
      }
    });
  };

  const handleSelectAll = () => {
    const nonUploadedBets = bets.filter((bet) => bet.status !== 2);
    if (selectedBets.length === nonUploadedBets.length) {
      setSelectedBets([]);
    } else {
      setSelectedBets(nonUploadedBets);
    }
  };

  const handleEditClick = (bet) => {
    setBetToEdit(bet);
    setCombione(bet.combione);
    setCombitwo(bet.combitwo);
    setCombithree(bet.combithree);
    setShowEditModal(true);
  };

  const handleDeleteClick = (bet) => {
    setBetToDelete(bet);
    setShowDeleteModal(true);
  };

  const handleEditSave = () => {
    const updatedBets = bets.map((bet) =>
      bet === betToEdit ? { ...bet, combione, combitwo, combithree } : bet
    );
    const sortedBets = updatedBets.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    setBets(sortedBets);
    sessionStorage.setItem("bet_data", JSON.stringify(sortedBets));
    setShowEditModal(false);
    toast.success("Bet removed!");
  };

  const handleDeleteConfirm = () => {
    const updatedBets = bets.filter((bet) => bet !== betToDelete);
    const sortedBets = updatedBets.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    setBets(sortedBets);
    sessionStorage.setItem("bet_data", JSON.stringify(sortedBets));
    setShowDeleteModal(false);
    toast.success("Bet removed!");
  };

  const handleUpload = () => {
    if (selectedBets.length === 0) {
      setShowWarningModal(true);
    } else {
      uploadBets();
    }
  };

  const uploadBets = async () => {
    try {
      const response = await fetch(getIp() + "/upload", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bets: selectedBets,
          userId: userId(),
        }),
      });
      if (response.ok) {
        toast.success("Bet[s] uploaded successfully!");
        const updatedBets = bets.map((bet) =>
          selectedBets.includes(bet) ? { ...bet, status: 2 } : bet
        );
        setBets(updatedBets);
        sessionStorage.setItem("bet_data", JSON.stringify(updatedBets));
        setSelectedBets([]);
      } else {
        toast.error("Failed to upload bets.");
      }
    } catch (error) {
      toast.error("Error uploading bets.");
    }
  };
  return (
    <>
          <Container className="p-3" style={{marginBottom: "80px"}}>
        <Toaster />
        <Row>
          <h1 style={{ fontWeight: "700" }}>New Bet</h1>
        </Row>
        <Row className="justify-content-center align-items-center mt-2">
          <Col xl={6} lg={4} xs={12}>
            <Card
              style={{
                borderRadius: "25px",
                borderColor: "transparent",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Card.Body>
                <Row className="justify-content-center align-items-center">
                  <Col xl={4} sm={4} xs={4}>
                    <Form.Control
                      size="lg"
                      type="text"
                      placeholder="01"
                      style={{
                        height: "12vh",
                        fontSize: "32px",
                        fontWeight: "bold",
                        borderRadius: "25px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                        borderColor: "transparent",
                        backgroundColor: "#161719",
                      }}
                      className="text-center"
                      value={combione}
                      onChange={handleInputChange(setCombione, inputTwoRef)}
                      onBlur={handleBlur(setCombione)}
                      ref={inputOneRef}
                    />
                  </Col>
                  <Col xl={4} sm={4} xs={4}>
                    <Form.Control
                      size="lg"
                      type="text"
                      placeholder="02"
                      style={{
                        height: "12vh",
                        fontSize: "32px",
                        fontWeight: "bold",
                        borderRadius: "25px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                        borderColor: "transparent",
                        backgroundColor: "#161719",
                      }}
                      className="text-center"
                      value={combitwo}
                      onChange={handleInputChange(setCombitwo, inputThreeRef)}
                      onBlur={handleBlur(setCombitwo)}
                      ref={inputTwoRef}
                    />
                  </Col>
                  <Col xl={4} sm={4} xs={4}>
                    <Form.Control
                      size="lg"
                      type="text"
                      placeholder="03"
                      style={{
                        height: "12vh",
                        fontSize: "32px",
                        fontWeight: "bold",
                        borderRadius: "25px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                        borderColor: "transparent",
                        backgroundColor: "#161719",
                      }}
                      className="text-center"
                      value={combithree}
                      onChange={handleInputChange(setCombithree)}
                      onBlur={handleBlur(setCombithree)}
                      ref={inputThreeRef}
                    />
                  </Col>
                  <Col xl={12} sm={12} xs={12}>
                    <div className="d-grid gap-2">
                      <Button
                        variant="dark"
                        size="lg"
                        className="mt-2"
                        style={{
                          height: "12vh",
                          fontSize: "32px",
                          fontWeight: "bold",
                          borderRadius: "25px",
                        }}
                        onClick={handleSaveButton}
                      >
                        SAVE
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Row className="justify-content-center">
              <Col xs={10} className="mt-2">
                <Row>
                  <Col xs={8}>
                    <Button variant="dark" onClick={handleSelectAll}>
                      {bets &&
                      selectedBets.length ===
                        bets.filter((bet) => bet.status !== 2).length
                        ? "Deselect All"
                        : "Select All"}
                    </Button>
                  </Col>
                </Row>

                <div
                  className="table-responsive mt-2"
                  style={{ maxHeight: "45vh",  scrollbarWidth: "none" }}
                >
                  {bets &&
                    bets.map((item, index) => (
                      <Card
                        className="mt-2"
                        key={index}
                        style={{
                          borderRadius: "25px",
                          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                          borderColor: "transparent",
                          backgroundColor: "#161719",
                        }}
                      >
                        <Card.Body>
                          <Row className="align-items-center justify-content-center">
                            <Col xs={1}>
                              <Form.Check
                                type="checkbox"
                                checked={selectedBets.includes(item)}
                                onChange={() => handleCheckboxChange(item)}
                                disabled={item.status === 2}
                              />
                            </Col>
                            <Col xs={7}>
                              <h5
                                className={
                                  item.status === 2 && "text-success-emphasis"
                                }
                              >
                                {" "}
                                {/* Add this class */}
                                <strong>
                                  {index + 1} . {item.combione}-{item.combitwo}-
                                  {item.combithree}{" "}
                                  {item.status === 1 && (
                                    <>
                                      <i
                                        className="bi bi-cloud-arrow-up"
                                        data-toggle="tooltip"
                                        title="For upload."
                                      ></i>
                                    </>
                                  )}
                                  {item.status === 2 && (
                                    <>
                                      <i
                                        className="bi bi-cloud-check text-success-emphasis"
                                        data-toggle="tooltip"
                                        title="Uploaded."
                                      ></i>
                                    </>
                                  )}
                                </strong>
                                <br />
                                <span style={{ fontSize: "12px" }}>
                                  <i
                                    className="bi bi-clock"
                                    data-toggle="tooltip"
                                    title="Uploaded."
                                  ></i>{"  "}
                                   <TimeOnlyComponent dateString={item.date} />
                                </span>
                              </h5>
                            </Col>
                            <Col xs={4}>
                              <Row>
                                <Col xs={6}>
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => handleEditClick(item)}
                                    disabled={item.status === 2} // Disable if uploaded
                                  >
                                    <i className="bi bi-pencil"></i>
                                  </button>
                                </Col>
                                <Col xs={6}>
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => handleDeleteClick(item)}
                                    disabled={item.status === 2} // Disable if uploaded
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Button variant="dark" className="floating-btn" onClick={handleUpload}>
        <i className="bi bi-cloud-arrow-up"></i>
        <div style={{ fontSize: "8px", padding: "0px", margin: "0px" }}>
          Upload
        </div>
      </Button>
      <BottomNavBar />
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Bet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editCombione">
              <Form.Label>Combination 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="01"
                value={combione}
                onChange={handleInputChange(setCombione)}
                onBlur={handleBlur(setCombione)}
              />
            </Form.Group>
            <Form.Group controlId="editCombitwo" className="mt-2">
              <Form.Label>Combination 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="02"
                value={combitwo}
                onChange={handleInputChange(setCombitwo)}
                onBlur={handleBlur(setCombitwo)}
              />
            </Form.Group>
            <Form.Group controlId="editCombithree" className="mt-2">
              <Form.Label>Combination 3</Form.Label>
              <Form.Control
                type="text"
                placeholder="03"
                value={combithree}
                onChange={handleInputChange(setCombithree)}
                onBlur={handleBlur(setCombithree)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEditSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Bet</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this bet?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          No bets selected. Please select at least one bet before uploading.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowWarningModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewBet;
