import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { userId, getIp, formatDate } from "./Vars";
import BottomNavBar from "./bottomnav";
import "./App.css";
import Header from "./Header";
import { CardBody } from "react-bootstrap";

import Carousel from "react-bootstrap/Carousel";

const Home = () => {
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);
  const [showCombinationModal, setShowCombinationModal] = useState(false);
  const [showPrizeModal, setShowPrizeModal] = useState(false);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  if (userId() === 0) {
    navigate("/");
  }

  const userData = sessionStorage.getItem("user");
  const parsedUser = JSON.parse(userData);
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(
    localStorage.getItem("dontShowTutorial") === "true"
  );

  const [paidBets, setPaidBets] = useState([]);
  const [filteredBets, setFilteredBets] = useState([]);
  const [statusCounts, setStatusCounts] = useState({
    forApproval: 0,
    approved: 0,
  });
  const [forPayment, setForPayment] = useState(0);

  useEffect(() => {
    // const updateDateTime = () => {
    //   const now = new Date();
    //   const date = now.toLocaleDateString();
    //   const time = now.toLocaleTimeString();
    //   setCurrentDateTime(`${date} ${time}`);
    // };

    // updateDateTime();
    // const intervalId = setInterval(updateDateTime, 1000); // update every second

    // return () => clearInterval(intervalId);

    const fetchBets = async () => {
      if (parsedUser[0].type === "usher") {
        try {
          const response = await fetch(
            `${getIp()}/getBets?user_id=${userId()}`
          );
          if (response.ok) {
            const data = await response.json();
            const forApprovalCount = data.filter(
              (bet) => bet.status === 2
            ).length;
            const approvedCount = data.filter((bet) => bet.status === 3).length;

            setPaidBets(data.filter((bet) => bet.status !== 1));
            setFilteredBets(data.filter((bet) => bet.status !== 1));
            setStatusCounts({
              forApproval: forApprovalCount,
              approved: approvedCount,
            });
          } else {
            console.error("Failed to fetch bets");
          }
        } catch (error) {
          console.error("Error fetching bets:", error);
        }
      }
      if (parsedUser[0].type === "operator") {
        try {
          const response = await fetch(
            `${getIp()}/getBetsOperator?user_id=${userId()}`
          );
          if (response.ok) {
            const data = await response.json();
            console.log(data);
            const forApprovalCount = data.filter(
              (bet) => bet.status === 2
            ).length;
            const approvedCount = data.filter((bet) => bet.status === 3).length;

            setPaidBets(data.filter((bet) => bet.status !== 1));
            setFilteredBets(data.filter((bet) => bet.status !== 1));
            setStatusCounts({
              forApproval: forApprovalCount,
              approved: approvedCount,
            });
          } else {
            console.error("Failed to fetch bets");
          }
        } catch (error) {
          console.error("Error fetching bets:", error);
        }
      }

      try {
        const betsData = sessionStorage.getItem("bet_data");
        if (betsData) {
          const parsedData = JSON.parse(betsData);
          const filteredData = parsedData.filter((bet) => bet.status === 2);
          setForPayment(filteredData.length - statusCounts.forApproval);
        } else {
          setForPayment(0);
        }
      } catch (error) {}
    };

    fetchBets();
  }, []);

  useEffect(() => {
    // Show the modal only if the user hasn't checked "do not show this again"
    if (!dontShowAgain) {
      setShowModal(true);
    }
  }, [dontShowAgain]);

  const handleCheckboxChange = () => {
    setDontShowAgain(!dontShowAgain);
    localStorage.setItem("dontShowTutorial", !dontShowAgain);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleCloseCombinationModal = () => setShowCombinationModal(false);
  const handleShowCombinationModal = () => setShowCombinationModal(true);

  const handleClosePrizeModal = () => setShowPrizeModal(false);
  const handleShowPrizeModal = () => setShowPrizeModal(true);

  return (
    <>
        <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header>
          <Modal.Title>
            Welcome {parsedUser[0].fname} {parsedUser[0].lname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-center">Betting steps.</h5>
          <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
              <div style={{ height: "400px", paddingTop: "100px" }}>
                <img
                  src="/images/NAV_BET.jpg"
                  alt="Navigation"
                  width={"100%"}
                />
              </div>
              <Carousel.Caption className="text-muted">
                {/* <h3>Step 1</h3> */}
                <p className="text-bg-info">
                  1. Click the BET {"("}{" "}
                  <i className="bi bi-bootstrap-fill"></i> {")"} navigation icon
                  to record your bets.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ height: "400px", paddingTop: "20px" }}>
                <img src="/images/STEP1.png" alt="Navigation" width={"100%"} />
              </div>

              <Carousel.Caption className="text-muted">
                {/* <h3>Step 2</h3> */}
                <p className="text-bg-info">
                  2. Input your desired combinations and click the{" "}
                  <strong>SAVE</strong> button.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ height: "400px", paddingTop: "100px" }}>
                <img
                  src="/images/UPLOAD_BUTTON.jpg"
                  alt="Navigation"
                  width={"100%"}
                />
              </div>

              <Carousel.Caption className="text-muted">
                {/* <h3>Step 2</h3> */}
                <p className="text-bg-info">
                  3. Select the bets you want to upload using the checkbox {"("}
                  <i className="bi bi-square"></i>
                  {")"}
                  and click the <strong>UPLOAD</strong> button.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ height: "400px", paddingTop: "100px" }}>
                <img
                  src="/images/NAV_PAYMENT.jpg"
                  alt="Navigation"
                  width={"100%"}
                />
              </div>
              <Carousel.Caption className="text-muted">
                {/* <h3>Third slide label</h3> */}
                <p className="text-bg-info">
                  4. Click the payment {"("} <i className="bi bi-paypal"></i>{" "}
                  {")"} navigation icon to pay for your uploaded bets.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ height: "400px" }}>
                <img
                  src="/images/STEP4_CLICK_PAY.jpg"
                  alt="Navigation"
                  width={"100%"}
                />
              </div>
              <Carousel.Caption className="text-muted">
                {/* <h3>Third slide label</h3> */}
                <p className="text-bg-info">
                  5. Click the <strong>PAY NOW</strong> button to open payment
                  methods.
                </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <div style={{ height: "400px" }}>
                <img
                  src="/images/STEP5_PAYMENT.png"
                  alt="Navigation"
                  width={"100%"}
                  style={{ margin: "0px auto" }}
                />
              </div>
              <Carousel.Caption className="text-muted">
                {/* <h3>Third slide label</h3> */}
                <p className="text-bg-info">
                  6. Click select your receipt in the{" "}
                  <strong>CHOOSE FILE</strong> input and click the UPLOAD button
                  to upload receipt to the server.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div style={{ height: "400px", paddingTop: "100px" }}>
                <img
                  src="/images/NAV_BET_LIST.jpg"
                  alt="Navigation"
                  width={"100%"}
                  style={{ margin: "0px auto" }}
                />
              </div>
              <Carousel.Caption className="text-muted">
                {/* <h3>Third slide label</h3> */}
                <p className="text-bg-info">
                  7. Finally, your uploaded bets are now for approval and you
                  can click {"("} <i className="bi bi-list-check"></i> {")"} to
                  check your bets.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Do not show this again"
              checked={dontShowAgain}
              onChange={handleCheckboxChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Got it!
          </Button>
        </Modal.Footer>
      </Modal>

      <Container className="p-3" style={{ marginBottom: "80px" }}>
        <Row className="">
          <h1 style={{ fontWeight: "700" }}>Dashboard</h1>
        </Row>
        <Row className="mt-2 justify-content-center align-items-center">
          <Col xs={12} className="mb-3">
            <Card
              className="h-100 bg-primary"
              style={{
                borderColor: "transparent",
                backgroundColor: "#161719",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "25px",
              }}
            >
              <Card.Body>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    alignContent: "end",
                    paddingLeft: "20px",
                    paddingTop: "10px",
                  }}
                >
                  <div>
                    <Card.Title
                      style={{ fontSize: "12px" }}
                      className="text-muted"
                    >
                      Total Gross
                    </Card.Title>
                    <Card.Text>
                      <h1 style={{ fontWeight: "800", fontSize: "65px" }}>
                        {statusCounts.approved * 10}
                      </h1>
                    </Card.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} className="mb-3">
            <Card
              className="h-100"
              style={{
                borderColor: "transparent",
                backgroundColor: "#161719",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "25px",
              }}
            >
              <Card.Body>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    alignContent: "end",
                  }}
                >
                  <i
                    className="bi bi-cloud-check"
                    style={{ fontSize: "36px", marginRight: "12px" }}
                  ></i>
                  <div>
                    <Card.Title style={{ fontSize: "12px" }}>
                      My Bets
                    </Card.Title>
                    <Card.Text>
                      <h1 style={{ fontWeight: "800" }}>
                        {statusCounts.approved}
                      </h1>
                    </Card.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} className="mb-3">
            <Card
              className="h-100"
              style={{
                borderColor: "transparent",
                backgroundColor: "#161719",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "25px",
              }}
            >
              <Card.Body>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    alignContent: "end",
                  }}
                >
                  <i
                    className="bi bi-cloud-fog2"
                    style={{ fontSize: "36px", marginRight: "12px" }}
                  ></i>
                  <div>
                    <Card.Title style={{ fontSize: "12px" }}>
                      For Approval
                    </Card.Title>
                    <Card.Text>
                      <h1 style={{ fontWeight: "800" }}>
                        {statusCounts.forApproval}
                      </h1>
                    </Card.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} className="text-center">
            <h1 style={{ fontWeight: "700" }}>Last Draw</h1> PICK 3
          </Col>
          <Row className="justify-content-center align-items-center p-3">
            <Col xs={4}>
              <Card
                style={{
                  borderRadius: "50%",
                  width: "120px",
                  height: "120px",
                  fontSize: "58px",
                  fontWeight: "900",
                  borderColor: "transparent",
                  backgroundColor: "#161719",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <CardBody>01</CardBody>
              </Card>
            </Col>
            <Col xs={4}>
              <Card
                style={{
                  borderRadius: "50%",
                  width: "120px",
                  height: "120px",
                  fontSize: "58px",
                  fontWeight: "900",
                  borderColor: "transparent",
                  backgroundColor: "#161719",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <CardBody>02</CardBody>
              </Card>
            </Col>
            <Col xs={4}>
              <Card
                style={{
                  borderRadius: "50%",
                  width: "120px",
                  height: "120px",
                  fontSize: "58px",
                  fontWeight: "900",
                  borderColor: "transparent",
                  backgroundColor: "#161719",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                }}
              >
                <CardBody>03</CardBody>
              </Card>
            </Col>
          </Row>
          <Col xs={12} className="text-center">
            <Card
              className=""
              style={{
                borderRadius: "25px",
                borderColor: "transparent",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <CardBody>
                <p
                  className="text-secondary"
                  style={{ fontSize: "25px", fontWeight: "900" }}
                >
                  Total Prize
                </p>
                <h1 style={{ fontWeight: "900", fontSize: "58px" }}>
                  ₱ 50,000.00
                </h1>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <BottomNavBar />
    </>
  );
};

export default Home;
