import React from 'react';

const convertToTimeOnly = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
};

const TimeOnlyComponent = ({ dateString }) => {
  const timeOnly = convertToTimeOnly(dateString);
  return <>{timeOnly}</>;
};

export default TimeOnlyComponent;