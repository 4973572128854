import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { userId, getIp, getUserData } from "./Vars";
import BottomNavBar from "./bottomnav";
import { toast, Toaster } from "react-hot-toast";
import "./App.css";

const Profile = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState(getUserData());
  const [newPin, setNewPin] = useState("");
  const [showPinModal, setShowPinModal] = useState(false);
  const [profilePic, setProfilePic] = useState(
    userData[0].profilePic || "/images/default.png"
  );
  const [profilePicFile, setProfilePicFile] = useState(null);

  useEffect(() => {
    if (userId() == null) {
      navigate("/");
      return;
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePinChange = async () => {
    if (newPin.trim() === "" || newPin.length !== 4) {
      alert("New PIN must be 4 digits long");
      return;
    }

    try {
      const response = await fetch(`${getIp()}/update-pin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId(),
          new_pin: newPin,
        }),
      });

      if (response.ok) {
        alert("PIN changed successfully!");
        setNewPin("");
        setShowPinModal(false);
      } else {
        alert("Failed to change PIN");
      }
    } catch (error) {
      alert("Error changing PIN");
    }
  };

  const handleNewPinChange = (e) => {
    const value = e.target.value;
    // Ensure value is only numeric and has at most 4 digits
    if (/^\d{0,4}$/.test(value)) {
      setNewPin(value);
    }
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    setProfilePicFile(file);
    setProfilePic(URL.createObjectURL(file));
  };

  const handleProfilePicUpload = async () => {
    if (!profilePicFile) {
      alert("Please select a profile picture to upload");
      return;
    }

    const formData = new FormData();
    formData.append("user_id", userId());
    formData.append("profile_pic", profilePicFile);

    try {
      const response = await fetch(`${getIp()}/upload-profile-pic`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("Profile picture uploaded successfully!");
        setProfilePicFile(null);
      } else {
        alert("Failed to upload profile picture");
      }
    } catch (error) {
      alert("Error uploading profile picture");
    }
  };

  const handleLogout = () => {
    // Add your logout logic here
    navigate("/login");
  };

  return (
    <>
     <Container className="p-3" style={{marginBottom: "80px"}}>
        <Toaster />
        <Row className="mb-2">
          <Col>
            <h1 style={{ fontWeight: "700" }}>My Profile</h1>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={12} className="text-center">
            <div className="profile-pic-container">
              <img
                src={profilePic}
                alt="Profile"
                className="profile-pic"
                style={{ borderRadius: "50%" }}
                width={120}
                height={120}
              />
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleProfilePicChange}
                className="mt-2"
              />
              <Button
                variant="primary"
                className="mt-2"
                onClick={handleProfilePicUpload}
              >
                Upload Picture
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={12}>
            <Card className="table-responsive" style={{ maxHeight: "60vh" }}>
              <Card.Header>
                Profile Information <a href="/" style={{ float: "right" }}>Logout</a>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col xs={12}>
                      <Form.Group controlId="formFname">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="fname"
                          value={userData[0].fname}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group controlId="formLname">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="lname"
                          value={userData[0].lname}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col xs={8}>
                      <Form.Group controlId="formPhone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="text"
                          name="phone"
                          value={userData[0].phone}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={4}>
                      <Form.Group controlId="formBirthday">
                        <Form.Label>Birthday</Form.Label>
                        <Form.Control
                          type="date"
                          name="birthday"
                          value={userData[0].birthday}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={6}>
                      <Form.Group controlId="formRegion">
                        <Form.Label>Region</Form.Label>
                        <Form.Control
                          type="text"
                          name="region_code"
                          value={userData[0].region_code}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group controlId="formProvince">
                        <Form.Label>Province</Form.Label>
                        <Form.Control
                          type="text"
                          name="province_code"
                          value={userData[0].province_code}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group controlId="formCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          name="city_code"
                          value={userData[0].city_code}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col xs={12}>
                      <Form.Group controlId="formMunicipality">
                        <Form.Label>Municipality</Form.Label>
                        <Form.Control
                          type="text"
                          name="municipality_code"
                          value={userData[0].municipality_code}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group controlId="formBarangay">
                        <Form.Label>Barangay</Form.Label>
                        <Form.Control
                          type="text"
                          name="barangay_code"
                          value={userData[0].barangay_code}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12}>
                      <Button
                        variant="secondary"
                        className="mt-2 w-100"
                        onClick={() => setShowPinModal(true)}
                      >
                        Change PIN
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className="text-center mt-3"></Container>

      <BottomNavBar />

      <Modal show={showPinModal} onHide={() => setShowPinModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change PIN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewPin">
              <Form.Label>New PIN</Form.Label>
              <Form.Control
                type="password"
                value={newPin}
                onChange={handleNewPinChange}
                placeholder="Enter new 4-digit PIN"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPinModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handlePinChange}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Profile;
