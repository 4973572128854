import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { userId, getIp, extractTime, maskName, formatDate } from "./Vars";
import "./App.css";
import BottomNavBar from "./bottomnav";
import { toast, Toaster } from "react-hot-toast";
import { Badge } from "react-bootstrap";

const BetList = () => {
  const navigate = useNavigate();
  const [uploadedBets, setUploadedBets] = useState([]);
  const [paidBets, setPaidBets] = useState([]);
  const [filteredBets, setFilteredBets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(2);
  const [acctData, setAcctData] = useState({});
  const [uploadSuccess, setUploadSuccess] = useState(false); // New state
 

  useEffect(() => {
    if (userId() === 0) {
      navigate("/");
      return;
    }

    const fetchBets = async () => {
      try {
        const response = await fetch(
          `${getIp()}/getUploads?user_id=${userId()}`
        );
        if (response.ok) {
          const data = await response.json();
          setUploadedBets(data.filter((bet) => bet.status === 1));
        } else {
          console.error("Failed to fetch bets");
        }
      } catch (error) {
        console.error("Error fetching bets:", error);
      }

      try {
        const response = await fetch(`${getIp()}/getBets?user_id=${userId()}`);
        if (response.ok) {
          const data = await response.json();
          setPaidBets(data.filter((bet) => bet.status !== 1));
          setFilteredBets(data.filter((bet) => bet.status !== 1));
        } else {
          console.error("Failed to fetch bets");
        }
      } catch (error) {
        console.error("Error fetching bets:", error);
      }

      try {
        const response = await fetch(`${getIp()}/getaccounts`);
        if (response.ok) {
          const data = await response.json();
          setAccounts(data);
          setAcctData(data[0]);
        } else {
          console.error("Failed to fetch accounts");
        }
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };


    fetchBets();
  }, [navigate]);

  useEffect(() => {
    if (uploadSuccess) {
      const fetchUpdatedBets = async () => {
        try {
          const response = await fetch(
            `${getIp()}/getUploads?user_id=${userId()}`
          );
          if (response.ok) {
            const data = await response.json();
            setUploadedBets(data.filter((bet) => bet.status === 1));
          } else {
            console.error("Failed to fetch updated bets");
          }
        } catch (error) {
          console.error("Error fetching updated bets:", error);
        }
      };

      fetchUpdatedBets();
      setUploadSuccess(false); // Reset upload success status
    }
  }, [uploadSuccess]);

  const handlePayNow = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setFile(null);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const totalAmount = uploadedBets.reduce(
    (total, bet) => total + bet.cnt * 10,
    0
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      toast.error("Please select a receipt first");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("userid", userId());
    formData.append("uploads", JSON.stringify(uploadedBets));
    formData.append("account", JSON.stringify(selectedAccount));

    try {
      const response = await fetch(`${getIp()}/uploadPayment?id=${userId()}`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        toast.success("Payment receipt sent to the operator.");
        setUploadSuccess(true); // Trigger the useEffect after a successful upload
        handleClose();
      } else {
        toast.error("Failed to upload the receipt due to errors");
      }
    } catch (error) {
      toast.error("Error occurred while uploading the file");
    }
  };

  const handleAccountChange = (e) => {
    setSelectedAccount(e.target.value);
    const user = accounts.find((user) => user.user_id == e.target.value);
    setAcctData(user);
  };
  return (
    <>
          <Container className="p-3" style={{marginBottom: "80px"}}>
        <Toaster />
        <Row>
          <h1 style={{ fontWeight: "700" }}>Payment</h1>
        </Row>
        <Row className="justify-content-center align-items-center mb-2 p-3">
          <Col xs={12}>
            <Card
              className="text-center"
              style={{
                minHeight: "15vh",
                borderRadius: "25px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                borderColor: "transparent",
                backgroundColor: "#161719",
              }}
            >
              <Card.Body>
                <h1
                  style={{ fontSize: "62px", fontWeight: "700" }}
                  className="text-center"
                >
                  ₱ {totalAmount}
                </h1>
                <Button
                  variant="dark"
                  className="mt-2 w-100"
                  style={{ borderRadius: "15px" }}
                  onClick={handlePayNow}
                  size="lg"
                  disabled={uploadedBets.length === 0}
                >
                  PAY NOW
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card
              className="table-responsive"
              style={{
                maxHeight: "60vh",
                minHeight: "60vh",
                borderRadius: "25px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                borderColor: "transparent",
              }}
            >
              <Card.Body>
                <h2 style={{ fontWeight: "700" }}>For Bets</h2>
                <div
                  style={{
                    paddingLeft: "25px",
                    paddingRight: "25px",
                    paddingTop: "15px",
                    marginTop: "15px",
                    paddingBottom: "15px",
                    borderRadius: "15px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                    borderColor: "transparent",
                    backgroundColor: "#161719",
                  }}
                >
                  {uploadedBets.length > 0 ? (
                    uploadedBets.map((bet, index) => (
                      <>
                        <Row className="align-items-center justify-content-end">
                          <Col xs={6}>
                            <div style={{ fontSize: "32px" }}>
                              <div
                                style={{ fontSize: "16px", fontWeight: "700" }}
                              >
                                Upload #{index + 1} [{bet.cnt} bets]
                              </div>
                              <p style={{ fontSize: "12px" }}>
                                Time Uploaded: {extractTime(bet.daterecorded)}
                              </p>
                            </div>
                          </Col>
                          <Col xs={6}>
                            <h3 style={{ fontWeight: "700", float: "right" }}>
                              ₱ {bet.cnt * 10}.00
                            </h3>
                          </Col>
                        </Row>
                      </>
                    ))
                  ) : (
                    <>No Recorded Bets</>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <BottomNavBar />

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-2 text-center">
            <Card.Body>
              {/* <i
                className="bi bi-exclamation-circle-fill text-danger"
                style={{ fontSize: "32px" }}
              ></i> */}
              <h1 style={{ fontSize: "72px" }}>
                ₱ <strong>{totalAmount}</strong>
              </h1>
              <p className="text-muted">Total amount to pay.</p>
            </Card.Body>
          </Card>
          <Row>
            <Col xs={12}>
              <Form.Group id="accountSelect">
                <Form.Label>Select Account</Form.Label>
                <Form.Control
                  as="select"
                  size="lg"
                  value={selectedAccount}
                  onChange={handleAccountChange}
                >
                  {accounts.length > 0 ? (
                    accounts.map((account, index) => (
                      <option key={index} value={account.user_id}>
                        Account #{index + 1}
                      </option>
                    ))
                  ) : (
                    <option>No accounts found.</option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Card className="mt-2">
            <Card.Header>Account Details</Card.Header>
            <Card.Body>
              <h5>
                GCash/Paymaya:{" "}
                <strong>{acctData?.phone || "Select an account"}</strong>
                <br />
                Name:{" "}
                <strong>
                  {acctData
                    ? maskName(
                        `${acctData.fname || ""} ${acctData.lname || ""}`
                      )
                    : "Select an account"}
                </strong>
              </h5>
              <p className="alert alert-warning">
                Please pay the exact amount of ₱ {totalAmount} to this account
                and upload the receipt below.
              </p>

              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formFileSm" className="mb-3 mt-3">
                  <Form.Control
                    type="file"
                    size="lg"
                    controlId="fileInput"
                    onChange={handleFileChange}
                  />
                </Form.Group>
                <Button
                  variant="light"
                  className="mb-3 w-100"
                  type="submit"
                  size="lg"
                >
                  <i className="bi bi-upload"></i> Upload
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BetList;
