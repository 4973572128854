import "./App.css";
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import NewBet from "./NewBet";
import Login from "./Login";
import Profile from "./Profile";
import BetList from "./Bets";
import { userId, getIp, extractTime } from "./Vars";
import ForApproval from "./ForApproval";
import ApprovedBetList from "./ApprovedBets";
import OperatorSettings from "./OperatorSettings";
// import ViewUploads from "./ViewUploads";
// import ViewAllUploads from "./ViewAllUploads";

function App() {
  const [type, setType] = useState();

  useEffect(() => {
    fetch(getIp() + "/gettype?user_id=" + userId())
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setType(data[0].type);
      })
      .catch((error) => console.error(error));
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/main" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route exact path="/newbet" element={<NewBet />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/bets" element={<BetList />} />
        <Route exact path="/paidbets" element={<ApprovedBetList />} />
        <Route exact path="/forapproval" element={<ForApproval />} />
        <Route exact path="/operatorspage" element={<OperatorSettings />} />
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
