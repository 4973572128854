import React, { useState, useEffect } from "react";
import { Navbar, Nav, Badge } from "react-bootstrap";
import { userId, getIp, formatDate, userType } from "./Vars";

const BottomNavBar = () => {
  const [type, setType] = useState();
  const [showBadge, setShowBadge] = useState(false);
  const [forApproval, setForApproval] = useState({});

  useEffect(() => {
    setType(userType());
    // console.log(userType());
    if (type === "operator") {
      const fetchBets = async () => {
        try {
          const response = await fetch(
            `${getIp()}/getForApproval?user_id=${userId()}`
          );
          if (response.ok) {
            const data = await response.json();
            if (data.length === 0) {
              setShowBadge(false);
            } else {
              setForApproval(data.length);
              setShowBadge(true);
            }
          } else {
            console.error("Failed to fetch bets");
          }
        } catch (error) {
          console.error("Error fetching bets:", error);
        }
      };
      fetchBets();
    }
  });
  return (
    <>
      <Navbar
        fixed="bottom"
        // className="bg-success-subtle"
        style={{ padding: "0px", backgroundColor: "#161719" }}
      >
        <Nav className="w-100 justify-content-around">
          {type === "operator" ? (
            <>
              <Nav.Link href="/dashboard">
                <i
                  className="bi bi-house-fill  text-white"
                  style={{ fontSize: "32px" }}
                ></i>
              </Nav.Link>
              <Nav.Link href="/forapproval">
                <i
                  className="bi bi-clipboard2-check-fill text-white"
                  style={{ fontSize: "32px" }}
                ></i>
                {showBadge && (
                  <Badge bg="danger" style={{ position: "absolute" }}>
                    {JSON.stringify(forApproval)}
                  </Badge>
                )}
              </Nav.Link>
              <Nav.Link href="/operatorspage">
                <i
                  className="bi bi-person-fill-gear text-white"
                  style={{ fontSize: "32px" }}
                ></i>
              </Nav.Link>
              <Nav.Link href="/profile">
                <i
                  className="bi bi-person-fill text-white"
                  style={{ fontSize: "32px" }}
                ></i>
              </Nav.Link>
            </>
          ) : (
            <>
              <Nav.Link href="/main">
                <i
                  className="bi bi-house-fill   text-white"
                  style={{ fontSize: "32px" }}
                ></i>
              </Nav.Link>
              <Nav.Link href="/newbet">
                <i
                  className="bi bi-bootstrap-fill   text-white"
                  style={{ fontSize: "32px" }}
                ></i>
              </Nav.Link>
              <Nav.Link href="/bets">
                <i
                  className="bi bi-paypal   text-white"
                  style={{ fontSize: "32px" }}
                ></i>
              </Nav.Link>
              <Nav.Link href="/paidbets">
                <i
                  className="bi bi-list-check   text-white"
                  style={{ fontSize: "32px" }}
                ></i>
              </Nav.Link>
              {/* <Nav.Link href="/forapproval">
                <i
                  className="bi bi-clipboard2-check-fill   text-white"
                  style={{ fontSize: "32px" }}
                ></i>
              </Nav.Link> */}
              <Nav.Link href="/profile">
                <i
                  className="bi bi-person-fill text-white"
                  style={{ fontSize: "32px" }}
                ></i>
              </Nav.Link>
            </>
          )}
        </Nav>
      </Navbar>
    </>
  );
};

export default BottomNavBar;
