import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import "./App.css";
import BottomNavBar from "./bottomnav";
import { userId, getIp, extractTime } from "./Vars";

const ApprovedBetList = () => {
  const navigate = useNavigate();
  const [paidBets, setPaidBets] = useState([]);
  const [filteredBets, setFilteredBets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusCounts, setStatusCounts] = useState({
    forApproval: 0,
    approved: 0,
  });
  const [forPayment, setForPayment] = useState(0);

  useEffect(() => {
    if (userId() == 0) {
      navigate("/");
      return;
    }

    const fetchBets = async () => {
      try {
        const response = await fetch(`${getIp()}/getBets?user_id=${userId()}`);
        if (response.ok) {
          const data = await response.json();
          const forApprovalCount = data.filter(
            (bet) => bet.status === 2
          ).length;
          const approvedCount = data.filter((bet) => bet.status === 3).length;

          setPaidBets(data.filter((bet) => bet.status !== 1));
          setFilteredBets(data.filter((bet) => bet.status !== 1));
          setStatusCounts({
            forApproval: forApprovalCount,
            approved: approvedCount,
          });
        } else {
          console.error("Failed to fetch bets");
        }
      } catch (error) {
        console.error("Error fetching bets:", error);
      }
    };

    try {
      const betsData = sessionStorage.getItem("bet_data");
      if (betsData) {
        const parsedData = JSON.parse(betsData);
        const filteredData = parsedData.filter((bet) => bet.status === 2);
        setForPayment(filteredData.length - statusCounts.forApproval);
      } else {
        setForPayment(0);
      }
    } catch (error) {}

    fetchBets();
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredBets(
      paidBets.filter((bet) =>
        `${bet.combione}-${bet.combitwo}-${bet.combithree}`
          .toLowerCase()
          .includes(term)
      )
    );
  };

  return (
    <>
      <Container className="p-3" style={{ marginBottom: "80px" }}>
        <Toaster />
        <Row className="mb-2">
          <Col>
            <h1 style={{ fontWeight: "700" }}>Bet List</h1>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={4}>
            <Card
              className="text-center"
              style={{
                borderRadius: "25px",
                borderColor: "transparent",
                backgroundColor: "#161719",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Card.Body>
                <Card.Text>
                  <h1 style={{ fontWeight: "700" }}>{forPayment}</h1>
                  <p className="text-muted" style={{ fontSize: "12px" }}>
                    For Payment
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={4}>
            <Card
              className="text-center"
              style={{
                borderRadius: "25px",
                borderColor: "transparent",
                backgroundColor: "#161719",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Card.Body>
                <Card.Text>
                  <h1 style={{ fontWeight: "700" }}>
                    {statusCounts.forApproval}
                  </h1>
                  <p className="text-muted" style={{ fontSize: "12px" }}>
                    For Approval
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={4}>
            <Card
              className="text-center"
              style={{
                borderRadius: "25px",
                borderColor: "transparent",
                backgroundColor: "#161719",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Card.Body>
                <Card.Text>
                  <h1 style={{ fontWeight: "700" }}>{statusCounts.approved}</h1>
                  <p className="text-muted" style={{ fontSize: "12px" }}>
                    Approved
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col xs={12}>
            <Form.Control
              type="text"
              placeholder="Search combinations"
              value={searchTerm}
              onChange={handleSearch}
              className="mt-2"
              style={{
                padding: "15px",
                borderRadius: "25px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                borderColor: "transparent",
                backgroundColor: "#161719",
              }}
            />
          </Col>
          <Col xs={10} className="mt-2">
            {filteredBets.length > 0 ? (
              filteredBets.map((bet, index) => (
                <Card
                  style={{
                    borderRadius: "25px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                    borderColor: "transparent",
                    marginBottom: "15px",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}
                >
                  <Card.Body>
                    <Row className="align-items-center justify-content-center">
                      <Col xs={6}>
                        <div key={index}>
                          <h3>
                            <strong>
                              {bet.combione +
                                "-" +
                                bet.combitwo +
                                "-" +
                                bet.combithree}
                            </strong>
                          </h3>
                          <i className="bi bi-clock-fill"></i>
                          <strong> {extractTime(bet.daterecorded)}</strong>
                          <br />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <strong>
                          {bet.status === 1 && (
                            <Badge className="bg-dark-subtle">
                              for payment
                            </Badge>
                          )}
                          {bet.status === 2 && (
                            <Badge className="bg-dark-subtle">
                              for approval
                            </Badge>
                          )}
                          {bet.status === 3 && (
                            <Badge className="bg-dark-subtle">approved</Badge>
                          )}
                        </strong>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <div>No paid bets found.</div>
            )}
          </Col>
        </Row>
      </Container>
      <BottomNavBar />
    </>
  );
};

export default ApprovedBetList;
