// export const fetchAndStoreIp = async () => {
//     let ip = "";
//     try {
//       const response = await fetch("https://localhost:3003/status");
//       if (response.ok) {
//         ip = "https://localhost:3003";
//       } else {
//         ip = "https://localhost:3003";
//       }
//     } catch (error) {
//       ip = "https://localhost:3003";
//     }

//     // Ensure sessionStorage is available and working
//     try {
//       sessionStorage.setItem("ip", ip);
//       console.log("Stored IP:", ip); // Debugging to ensure it's stored correctly
//     } catch (storageError) {
//       console.error("Session Storage Error:", storageError);
//     }
//   };

export const getIp = () => {
  //return sessionStorage.getItem("ip");
  return "http://server.islabet.co:3003";
};

export const userId = () => {
  
  let id = 0;
  if (sessionStorage.getItem("user")) {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (Array.isArray(userData) && userData.length > 0) {
      id = userData[0].user_id;
    }
  }
  return id;
};

export const userType = () => {
  let type = null;
  if (sessionStorage.getItem("user")) {
    const userData = JSON.parse(sessionStorage.getItem("user"));
    if (Array.isArray(userData) && userData.length > 0) {
      type = userData[0].type;
    }
  }
  return type;
};

export const getUserData = () => {
  const userData = JSON.parse(sessionStorage.getItem("user"));

  return userData;
};

export const formatDate = (date) => {
  const pad = (num) => String(num).padStart(2, "0");
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds()
  )}`;
};

export const formatDateOnly = (date) => {
  const pad = (num) => String(num).padStart(2, "0");
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`
};

export const extractTime = (dateTimeString) => {
  const timeOnly = dateTimeString.split(" ")[1];
  return timeOnly;
};

export const maskName = (fullName) => {
  return fullName
    .split(" ")
    .map((name) => {
      if (name.length > 1) {
        return name[0] + "*".repeat(name.length - 1);
      } else {
        return name; // handle case where the name is only one letter
      }
    })
    .join(" ");
};
