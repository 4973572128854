import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { userId, formatDate, formatDateOnly } from "./Vars";
import BottomNavBar from "./bottomnav";
import "./App.css";
import Header from "./Header";

const OperatorSettings = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(formatDateOnly(new Date()));
  const [generateBetsLink, setGenerateBetsLink] = useState();

  const [showBetListModal, setShowBetListModal] = useState(false);
  const [showGenerateBetsModal, setShowGenerateBetsModal] = useState(false);
  const [showWinningCombinationsModal, setShowWinningCombinationsModal] =
    useState(false);
  const [showTotalPrizeModal, setShowTotalPrizeModal] = useState(false);

  const handleClose = () => {
    setShowBetListModal(false);
    setShowGenerateBetsModal(false);
    setShowWinningCombinationsModal(false);
    setShowTotalPrizeModal(false);
  };

  const handleShow = (modal) => {
    switch (modal) {
      case "betList":
        setShowBetListModal(true);
        break;
      case "generateBets":
        setShowGenerateBetsModal(true);
        break;
      case "winningCombinations":
        setShowWinningCombinationsModal(true);
        break;
      case "totalPrize":
        setShowTotalPrizeModal(true);
        break;
      default:
        break;
    }
  };

  if (userId() === 0) {
    navigate("/");
  }

  const userData = sessionStorage.getItem("user");
  const parsedUser = JSON.parse(userData);

  return (
    <>
      <Container className="p-3" style={{ marginBottom: "80px" }}>
        {/* <Header /> */}
        <Row className="">
          <h1 style={{ fontWeight: "700" }}>Operator Functions</h1>
        </Row>
        <Row className="justify-content-center align-items-center mb-4">
          <Col xs={12} md={6}>
            <Form>
              <Form.Group controlId="datePicker">
                <Form.Label>Select Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedDate}
                  onChange={(e) => [setSelectedDate(e.target.value), setGenerateBetsLink(`https://www.spartan-bean.com/s3/generates3.php?date=${e.target.value}`)]}
                />
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center">
          <Col xs={12}>
            <h1>Bet Settings</h1>
          </Col>
          <Col xs={6} className="mb-2">
            <Button
              variant="primary"
              className="w-100"
              style={{
                borderRadius: "25px",
                padding: "25px",
                fontSize: "16px",
                height: "80px",
              }}
              onClick={() => handleShow("betList")}
            >
              <i className="bi bi-bootstrap-fill"></i> Bet List
            </Button>
          </Col>

          <Col xs={6} className="mb-2">
            <a
              href={generateBetsLink}
              target="_blank"
              className="w-100 btn btn-info"
              style={{
                borderRadius: "25px",
                padding: "25px",
                fontSize: "16px",
                height: "80px",
              }} rel="noreferrer"
            >
              <i className="bi bi-database-fill-gear"></i> Generate Bets
            </a>
          </Col>
          <Col xs={12}>
            <h1>Dashboard Settings</h1>
          </Col>
          <Col xs={6} className="mb-2">
            <Button
              variant="info"
              className="w-100"
              style={{
                borderRadius: "25px",
                padding: "25px",
                fontSize: "16px",
                height: "80px",
              }}
              onClick={() => handleShow("winningCombinations")}
            >
              <i className="bi bi-check-circle"></i> Winning Combinations
            </Button>
          </Col>
          <Col xs={6} className="mb-2">
            <Button
              variant="primary"
              className="w-100"
              style={{
                borderRadius: "25px",
                padding: "25px",
                fontSize: "16px",
                height: "80px",
              }}
              onClick={() => handleShow("totalPrize")}
            >
              <i className="bi bi-cash"></i> Total Prize
            </Button>
          </Col>
        </Row>
      </Container>

      {/* Modals */}
      <Modal show={showBetListModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Bet List</Modal.Title>
        </Modal.Header>
        <Modal.Body>{/* Your Bet List content goes here */}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} centered>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showGenerateBetsModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Generate Bets</Modal.Title>
        </Modal.Header>
        <Modal.Body>{/* Your Generate Bets content goes here */}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showWinningCombinationsModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Winning Combinations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="combination1">
              <Form.Label>Combination 1</Form.Label>
              <Form.Control type="text" placeholder="Enter combination 1" />
            </Form.Group>
            <Form.Group controlId="combination2">
              <Form.Label>Combination 2</Form.Label>
              <Form.Control type="text" placeholder="Enter combination 2" />
            </Form.Group>
            <Form.Group controlId="combination3">
              <Form.Label>Combination 3</Form.Label>
              <Form.Control type="text" placeholder="Enter combination 3" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTotalPrizeModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Total Prize</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="totalPrize">
              <Form.Label>Total Prize</Form.Label>
              <Form.Control type="text" placeholder="Enter total prize" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <BottomNavBar />
    </>
  );
};

export default OperatorSettings;
