import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Navbar } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import OTPInput from "otp-input-react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "./firebase.config";
import { toast, Toaster } from "react-hot-toast";
import PinInput from "react-pin-input";
import { getIp } from "./Vars";
import "./App.css";

const Login = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [showPinInput, setShowPinInput] = useState(false);
  const [pin, setPin] = useState("");

  const handleLoginClick = () => {
    // Hide phone number input and show pin input
    // console.log(getIp())
    setShowPinInput(true);
  };

  const handleGoBack = () => {
    // Hide phone number input and show pin input
    setShowPinInput(false);
  };

  const verifyPinInput = (val) => {
    fetch(getIp() + "/login?pin=" + val + "&phone=" + ph)
      .then((response) => response.json())
      .then((data) => {
        if (data[0].cnt == 1) {
          toast.success("Login successful!");
          sessionStorage.setItem("user", JSON.stringify(data));
          // console.log(data[0].user_id);
          navigate("/main");
        } else {
          toast.error("Login failed.");
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <Container className="p-3">
      <Row className="justify-content-center align-items-center">
        <Col xl={8} xs={12}>
          <Toaster />
          <h1 className="text-center">
            <strong>3 Balls Online Betting</strong>
          </h1>
          <div className="w-80 flex flex-col gap-4 rounded-lg p-4 text-center">
            <i
              className="bi bi-shield-lock-fill"
              style={{ fontSize: "50px" }}
            ></i>
          </div>
        </Col>
        <Col xl={8} xs={12}>
          {!showPinInput ? (
            <>
              <p className="text-center">Please enter your phone number.</p>
              <PhoneInput
                country={"ph"}
                value={ph}
                inputStyle={{ width: "100%",borderRadius: "25px", backgroundColor:"#161719", borderColor: "#161719" }}
                onChange={setPh}
              />
              <div className="d-grid gap-2 mt-2">
                <Button
                  variant="secondary"
                  size="lg"
                  className="mt-2"
                  onClick={handleLoginClick}
                  disabled={loading}
                  style={{borderRadius: "25px", }}
                >
                  Enter
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="text-center">Please enter your pin to continue.</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Form>
                  <PinInput
                    length={4}
                    initialValue=""
                    secret
                    secretDelay={100}
                    onChange={(value, index) => [setPin(value)]}
                    type="numeric"
                    inputMode="number"
                    inputStyle={{
                      borderColor: "transparent",
                      borderRadius: "25px",
                      marginLeft: "20px",
                      height: "8vh",
                      width: "8vh",
                      backgroundColor: "#dee2e6",
                      color: "#161719",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)"
                    }}
                    inputFocusStyle={{ borderColor: "transparent", backgroundColor: "#161719",    color: "#dee2e6",   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)" }}
                    onComplete={(value, index) => [verifyPinInput(value)]}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                </Form>
              </div>
              <div className="d-grid gap-2 mt-2">
                <Button
                  variant="secondary"
                  size="lg"
                  className="mt-2"
                  onClick={handleGoBack}
                  disabled={loading}
                  style={{borderRadius: "25px", }}
                >
                  Back
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
