import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { userId, getIp, extractTime } from "./Vars";
import "./App.css";
import BottomNavBar from "./bottomnav";
import { toast, Toaster } from "react-hot-toast";

const ForApproval = () => {
  const navigate = useNavigate();
  const [paidBets, setPaidBets] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const fetchBets = async () => {
    try {
      const response = await fetch(
        `${getIp()}/getForApproval?user_id=${userId()}`
      );
      if (response.ok) {
        const data = await response.json();
        setPaidBets(data);
      } else {
        console.error("Failed to fetch bets");
      }
    } catch (error) {
      console.error("Error fetching bets:", error);
    }
  };
  useEffect(() => {
    fetch(getIp() + "/gettype?user_id=" + userId())
      .then((response) => response.json())
      .then((data) => {
        if (data[0].type !== "operator") {
          navigate("/");
          return;
        }
      })
      .catch((error) => console.error(error));
    if (userId() == 0) {
      navigate("/");
      return;
    }
    fetchBets();
  }, []);

  const handleApprove = async (upload_id) => {
    const confirmApproval = window.confirm("Are you sure you want to approve this upload?");
    if (!confirmApproval) {
      return;
    }
  
    try {
      const response = await fetch(`${getIp()}/approveBet`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ upload_id }),
      });
      if (response.ok) {
        setPaidBets((prevBets) =>
          prevBets.map((bet) =>
            bet.upload_id === upload_id ? { ...bet, status: 3 } : bet
          )
        );
        fetchBets();
        toast.success("Approved");
      } else {
        console.error("Failed to approve bet");
      }
    } catch (error) {
      console.error("Error approving bet:", error);
    }
  };

  // const handleDisapprove = async (upload_id) => {
  //   try {
  //     const response = await fetch(`${getIp()}/disapproveBet`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ user_id: userId(), upload_id }),
  //     });
  //     if (response.ok) {
  //       setPaidBets((prevBets) =>
  //         prevBets.filter((bet) => bet.upload_id !== upload_id)
  //       );
  //     } else {
  //       console.error("Failed to disapprove bet");
  //     }
  //   } catch (error) {
  //     console.error("Error disapproving bet:", error);
  //   }
  // };

  // const totalAmount = paidBets.reduce((total, bet) => total + bet.cnt * 10, 0);

  const handleShowModal = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage("");
  };

  return (
    <>
          <Container className="p-3" style={{marginBottom: "80px"}}>
        <Toaster />
        <Row className="mb-2">
         <h1 style={{ fontWeight: "700" }}>For Approval<span style={{float: "right", fontSize: "16px"}}>Total: {paidBets.length}</span></h1>
        </Row>
        <Row>
          <Col xs={12}>
            {/* <h5 style={{ fontWeight: "700" }} className="mb-3">
                  Total: ₱ {totalAmount}.00
                </h5> */}
            {paidBets.length > 0 ? (
              paidBets.map((bet, index) => (
                <Card
                  key={bet.upload_id}
                  style={{
                    borderRadius: "25px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                    borderColor: "transparent",
                    backgroundColor: "#161719",
                  }}
                  className="mb-2"
                >
                  <Card.Body>
                    <Row className="align-items-center justify-content-end mb-3">
                      <Col xs={6}>
                        <h5 style={{ fontSize: "18px", fontWeight: "700" }}>
                          {index + 1}. {bet.fname + " " + bet.lname}
                        </h5>
                        <p style={{ fontSize: "14px" }}>
                          <i className="bi bi-clock"></i>{" "}
                          {extractTime(bet.daterecorded)}
                        </p>
                      </Col>
                      <Col xs={6}>
                        <h5 style={{ fontWeight: "700", float: "right" }}>
                          +{bet.cnt * 10}.00
                          <div>
                            <a
                              href="#"
                              onClick={() =>
                                handleShowModal(
                                  `${getIp()}/uploads/${bet.receipt_image}`
                                )
                              }
                              style={{
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                            >
                              View Receipt
                            </a>
                          </div>
                        </h5>
                      </Col>
                      <Col xs={8}>
                        {bet.status !== 3 && (
                          <Row className="mt-2 align-content-end justify-content-end">
                            {/* <Col xs={6}>
                                  <Button
                                    variant="dark w-100"
                                    size="sm"
                                    style={{ fontWeight: "600" }}
                                    onClick={() =>
                                      handleDisapprove(bet.upload_id)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                </Col> */}
                            <Col xs={8}>
                              <Button
                                variant="primary"
                                className="w-100 bg-primary"
                                size="sm"
                                style={{
                                  fontWeight: "600",
                                  borderColor: "transparent",
                                  backgroundColor: "#161719",
                                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                                  borderRadius: "25px",
                                }}
                                onClick={() => handleApprove(bet.upload_id)}
                              >
                                Approve
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))
            ) : (
              <ListGroup.Item>No uploaded bets pending payment.</ListGroup.Item>
            )}
          </Col>
        </Row>
      </Container>
      <BottomNavBar />

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Receipt Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Receipt"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ForApproval;
